import { StrictUseAxiosReturn } from '@vueuse/integrations';
import { useAxios } from '@vueuse/integrations/useAxios';
import { AxiosResponse } from 'axios';
import { computed, MaybeRef, ref, toValue, watch } from 'vue';

import { client } from '$common/services/http.ts';
import { formatSortParams, QTablePagination } from '$tenant/composables/pagination.ts';

export type RbacRole = Required<Pick<App.Models.RbacRole, 'id' | 'name'>> &
  Omit<App.Models.RbacRole, 'id' | 'name'>;

export interface FetchRbacRoleOptions {
  pagination?: MaybeRef<QTablePagination>;
  search?: MaybeRef<string | null>;
  fields?: ('id' | 'name' | 'label')[];
}

export const useFetchRbacRoles = ({ pagination, search, fields }: FetchRbacRoleOptions) => {
  const params = computed(() => {
    const paginationValue = toValue(pagination);

    return {
      page: paginationValue?.page || 1,
      perPage: paginationValue?.rowsPerPage || 24,
      filter: { search: toValue(search) },
      sort: formatSortParams(pagination),
      include: 'usersCount',
      fields,
    };
  });

  const {
    data: response,
    execute,
    isLoading,
    abort,
  } = useAxios<App.Paginate<RbacRole>>('', client, { immediate: false });
  const data = ref<RbacRole[]>([]);
  watch(response, (res) => (data.value = res?.data ?? []));

  return {
    isLoading,
    data,
    abort,
    execute: () => execute('/api/rbac/roles', { params: toValue(params) }),
  };
};

export const useSearchRbacRoles = () => {
  const { data, execute, isLoading } = useAxios<RbacRole[]>('', client, { immediate: false });

  return {
    isLoading,
    data,
    execute: (params: unknown) => {
      return execute('/api/rbac/roles', { params });
    },
  };
};

export const useGetRbacRole = () => {
  const { data, execute, isLoading } = useAxios<RbacRole>('', client, {
    immediate: false,
  });

  return {
    isLoading,
    data,
    execute: async (
      id: MaybeRef<string | number>,
      params?: {
        include?: ('users' | 'users_count')[];
        fields?: ('id' | 'name' | 'label' | 'users.name' | 'users.id' | 'users.email')[];
      },
    ) => {
      return execute(`/api/rbac/roles/${toValue(id)}`, {
        method: 'GET',
        params,
      });
    },
  };
};

export const useFetchRbacRoleForUser = () => {
  const { data, execute, isLoading, abort } = useAxios<RbacRole[]>('', client, {
    immediate: false,
    initialData: [],
  });

  return {
    isLoading,
    data,
    abort,
    execute: <T>(
      id: MaybeRef<string | number>,
      params?: {
        fields?: ('id' | 'name' | 'label')[];
      },
    ) =>
      execute(`/api/users/${toValue(id)}/rbac/roles`, {
        method: 'GET',
        params,
      }) as Promise<StrictUseAxiosReturn<T, AxiosResponse<T, unknown>, unknown>>,
  };
};

export const useAttachRbacRoleToUser = () => {
  const { data, execute, isLoading, abort } = useAxios<{ success: boolean }>('', client, {
    immediate: false,
    abortPrevious: false,
  });

  return {
    isLoading,
    data,
    abort,
    execute: (userId: MaybeRef<string | number>, roleId: MaybeRef<string | number>) =>
      execute(`/api/users/${toValue(userId)}/rbac/roles`, {
        method: 'post',
        data: { role_id: toValue(roleId) },
      }),
  };
};

export const useDetachRbacRoleToUser = () => {
  const { data, execute, isLoading, abort } = useAxios<{ success: boolean }>('', client, {
    immediate: false,
    abortPrevious: false,
  });

  return {
    isLoading,
    data,
    abort,
    execute: (userId: MaybeRef<string | number>, roleId: MaybeRef<string | number>) =>
      execute(`/api/users/${toValue(userId)}/rbac/roles`, {
        method: 'delete',
        data: { role_id: toValue(roleId) },
      }),
  };
};
