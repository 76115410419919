import { StrictUseAxiosReturn } from '@vueuse/integrations';
import { useRouteQuery } from '@vueuse/router';
import { QTableProps } from 'quasar';
import { MaybeRef, onBeforeMount, ref, toValue, watch } from 'vue';

export type QTablePagination = Required<QTableProps['pagination']>;

export interface QTableOnRequest {
  pagination: QTablePagination;
  filter?: string;
}

export type PaginatedAxiosResponse<T = unknown> = Promise<
  StrictUseAxiosReturn<App.Paginate<T>, unknown, unknown>
>;

const createDefaultPagination = () => ({
  sortBy: 'id',
  descending: false,
  page: 1,
  rowsPerPage: 24,
  rowsNumber: 0,
});

const createDefaultOptions = () => ({
  withQuery: true,
});

export const useQTablePagination = <T>(
  initial?: Partial<QTablePagination>,
  options?: { withQuery?: boolean },
) => {
  const { withQuery } = { ...createDefaultOptions(), ...toValue(options ?? {}) };
  const initialValues = { ...createDefaultPagination(), ...toValue(initial ?? {}) };
  const pagination = ref<Required<QTablePagination>>(structuredClone(initialValues));

  if (withQuery) {
    const page = useRouteQuery('page');
    const rowsPerPage = useRouteQuery('per-page');
    const sort = useRouteQuery('sort');
    const direction = useRouteQuery('direction');

    onBeforeMount(() => {
      if (!pagination?.value) {
        return;
      }

      if (page.value) {
        pagination.value.page = Number(page.value);
      }
      if (rowsPerPage.value) {
        pagination.value.rowsPerPage = Number(rowsPerPage.value);
      }
      if (sort.value) {
        pagination.value.sortBy = sort?.value as string;
      }
      if (direction.value) {
        pagination.value.descending = direction?.value === 'desc';
      }
    });

    watch(
      () => pagination.value,
      (newPagination) => {
        if (!newPagination) {
          return;
        }

        page.value = newPagination.page.toString();
        rowsPerPage.value = newPagination.rowsPerPage.toString();
        sort.value = newPagination.sortBy;
        direction.value = newPagination.descending ? 'desc' : 'asc';
      },
      { deep: true, immediate: true },
    );
  }

  const onRequest = async (props: QTableOnRequest, cb: () => PaginatedAxiosResponse<T>) => {
    if (pagination?.value) {
      pagination.value.page = props.pagination?.page ?? pagination.value.page;
      pagination.value.rowsPerPage = props.pagination?.rowsPerPage ?? pagination.value.rowsPerPage;
      pagination.value.rowsPerPage = props.pagination?.rowsPerPage ?? pagination.value.rowsPerPage;
      pagination.value.sortBy = props.pagination?.sortBy || 'id';
      pagination.value.descending = props.pagination?.descending ?? pagination.value.descending;

      const payload = toValue((await cb())?.data ?? {});
      pagination.value.rowsNumber = payload?.total ?? pagination.value.rowsNumber;
    }
  };

  return {
    pagination,
    onRequest,
  };
};

export const formatSortParams = (pagination: MaybeRef<QTablePagination>) => {
  const paginationValue = toValue(pagination);

  return paginationValue?.sortBy
    ? `${paginationValue.descending ? '-' : ''}${paginationValue?.sortBy}`
    : undefined;
};
