import { MaybeRef, toValue } from 'vue';

import { User } from '$common/modules/auth/types.ts';
import { client } from '$common/services/http';

/**
 * Refresh the sanctum cookie.
 */
export const csrf = async () => {
  return client.get('/sanctum/csrf-cookie');
};

/**
 * Attempt to login with given credentials.
 */
export const login = async (email: string, password: string) => {
  // extra cookie refresh to be sure the session is up and running
  await csrf();

  const { data } = await client.post('/api/login', { email, password });

  return data;
};

/**
 * Logout current user.
 */
export const logout = async () => {
  const { data } = await client.post('/api/logout');

  return data;
};

/**
 * Retrieves the current user.
 */
export const me = async () => {
  const { data } = await client.get<User>('/api/me');

  return data;
};

export interface PasswordChangePayload {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

export const passwordChange = async (payload: MaybeRef<PasswordChangePayload>) => {
  return (await client.post('/api/auth/password-change', toValue(payload)))?.data ?? {};
};

export const forgottenPassword = async (email: MaybeRef<string>) => {
  return (await client.post('/api/forgotten-password', { email: toValue(email) }))?.data ?? {};
};

export interface RequestResetPasswordPayload {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export const requestResetPassword = async (payload: MaybeRef<RequestResetPasswordPayload>) => {
  return (await client.post('/api/reset-password', toValue(payload)))?.data ?? {};
};
