import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { middlewarePipeline, NavigationGuardWithContext } from './middleware-processor.ts';

import { setTitle } from '$common/utils/page-title.ts';

export const bootstrapRouter = (routes: RouteRecordRaw[]) => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
  });

  // middleware pipeline
  router.beforeEach((to, from, next) => {
    const middlewares = to.matched
      .flatMap(({ meta }) => meta.middlewares)
      .filter((s) => s) as NavigationGuardWithContext[];

    middlewarePipeline(middlewares, from, to, next, 0);
  });

  // set document title on each navigation
  router.beforeEach((to) => {
    setTitle((to.meta?.title as string | undefined) ?? '');
  });

  return router;
};
