import {
  Dialog,
  Loading,
  Notify,
  QAvatar,
  QCard,
  QCardActions,
  QInput,
  QMenu,
  QPage,
  QSelect,
  QTable,
  QuasarPluginOptions,
} from 'quasar';
import quasarIconSet from 'quasar/icon-set/svg-fontawesome-v6';
import quasarLang from 'quasar/lang/fr';

// Import icon libraries
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css';

import { setQuasarComponentDefaultPropValues } from './set-quasar-component-default-prop-values.ts';

// Import Quasar css

setQuasarComponentDefaultPropValues(QTable, {
  bordered: true,
  flat: true,
  rowsPerPageOptions: [10, 24, 50, 100, 500],
});

setQuasarComponentDefaultPropValues(QCard, {
  bordered: true,
  flat: true,
});

setQuasarComponentDefaultPropValues(QCardActions, {
  align: 'right',
});

setQuasarComponentDefaultPropValues(QInput, {
  filled: true,
  hideBottomSpace: true,
});

setQuasarComponentDefaultPropValues(QSelect, {
  filled: true,
  hideBottomSpace: true,
});

setQuasarComponentDefaultPropValues(QPage, {
  padding: true,
});

setQuasarComponentDefaultPropValues(QAvatar, {
  rounded: true,
});

setQuasarComponentDefaultPropValues(QMenu, {
  transitionShow: 'jump-down',
  transitionHide: 'jump-up',
});

export function createQuasarOptions(): Partial<QuasarPluginOptions> {
  return {
    // @ts-expect-error plugins option is not typed to receive an array
    plugins: [Loading, Notify, Dialog],
    lang: quasarLang,
    iconSet: quasarIconSet,
    config: {
      dark: 'auto',
    },
  };
}
