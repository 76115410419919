let titleValue = '';
let resourceNameValue = '';

export function setTitle(title: string) {
  titleValue = title;

  updateTitle();
}

export function setResourceName(name: string) {
  resourceNameValue = name;

  updateTitle();
}

function updateTitle() {
  if (!titleValue) {
    document.title = 'MADMS';
    return;
  }

  if (/:resource:/.test(titleValue)) {
    document.title = `${titleValue.replace(':resource:', resourceNameValue)} - MADMS`;
    return;
  }

  document.title = `${titleValue} - MADMS`;
}

export function getTitle() {
  return document.title.replace(' - MADMS', '');
}
