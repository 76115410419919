import type { CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { stringify } from 'qs';

import { useAuthStore } from '$common/modules/auth/stores/auth.ts';

const config: CreateAxiosDefaults = {
  withCredentials: true,
  paramsSerializer: (params) => stringify(params),
  headers: {
    common: {
      ['X-Requested-With']: 'XMLHttpRequest',
    },
  },
};

export interface QueryParams {
  include?: string[];
  fields?: string[];
  sort?: string[];
  select?: string[];
  filter?: (`${string}` | `-${string}`)[];
}

const separator = ',';

const paramFormatter = (params: QueryParams) => {
  return Object.keys(params).reduce(
    (acc, paramsName) => ({
      ...acc,
      // @ts-expect-error quasar QueryParams is not properly typed as array-like
      [paramsName]: params[paramsName].join(separator),
    }),
    {},
  );
};

const client = axios.create(config);

client.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (location.pathname !== '/login' && error?.response?.status === 401) {
      const store = useAuthStore();
      await store.logout();
    }

    return Promise.reject(error);
  },
);

export { client, paramFormatter };
