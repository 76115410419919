import { ComponentPublicInstance } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

type NavigationGuardNextCallback = (vm: ComponentPublicInstance) => unknown;
type NavigationGuardReturn =
  | void
  | Error
  | RouteLocationRaw
  | boolean
  | NavigationGuardNextCallback;

export declare interface NavigationGuardWithContext {
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    ctx?: unknown,
  ): NavigationGuardReturn | Promise<NavigationGuardReturn>;
}

export function middlewarePipeline(
  guards: NavigationGuardWithContext[],
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  RouterNext: NavigationGuardNext,
  i: number,
  ctx?: unknown,
) {
  const guard = guards[i];
  // no middleware supplied
  if (guards.length === 0) {
    RouterNext();
    // last middleware
  } else if (guards.length === i + 1) {
    void guard(from, to, RouterNext, ctx);
    // normal operation
  } else {
    void guard(
      from,
      to,
      <NavigationGuardNext>function (args: NavigationGuardReturn) {
        if (typeof args === 'undefined') {
          middlewarePipeline(guards, from, to, RouterNext, i + 1, ctx);
          return;
        }

        // @ts-expect-error well the type is not picked properly....
        RouterNext(args);
      },
      ctx,
    );
  }
}
